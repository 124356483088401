import styled from "styled-components";
import { V3SubtitleMedium } from "../../../components/Typography";
import { media } from "../../../theme/theme";

export const CertificatePanelWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

    ${media.custom(600)} {
        flex-direction: row;
    }
    
    > div {
        &:first-child,
        &:nth-child(2) {
            flex-shrink: 0;
        }

        &:last-child {
            max-width: 400px;
            margin-top: 26px;

            ${media.custom(1188)} {
                margin-top: 0;
                margin-left: auto;
            }
        }

        &:not(:last-child) {
            padding-right: 30px;
        }
        
    }
`;

export const CertificateSeason = styled(V3SubtitleMedium)`
    color: ${({ theme }) => theme.v3.colors.grey48};
`;

export const CertificatePaneBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div {
        display: inline-flex;
        
        &:not(:last-child) {
            padding-right: 6px;
        }
    }
`;

export const CertSVG = styled.svg<{ $maxWidth: number }>`
    width: 100%;
    height: auto;
    max-width: ${({ $maxWidth }) => `${$maxWidth}px`};

    @media print {
        max-width: 150px;
    }
`;