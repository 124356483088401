import toast from "react-hot-toast";
import { AuthenticatedUserResponse, CertificateResponse } from "authory-api-types/dist/types";
import { V3Button } from "../../../components/Button";
import { V3ButtonColor } from "../../../components/Button/V3Button.types";
import { V3IconDownload, V3IconCollections, V3IconSend, V3IconImage, V3IconPDF, V3IconFacebook, V3IconTwitter, V3IconLinkedin, V3IconHelpCircle } from "../../../components/Icons";
import { V3ToggleWithLabel } from "../../../components/ToggleSwitch";
import { V3HeadlineH3 } from "../../../components/Typography";
import { V3CCDisabledInput } from "../../../components/V3CopyToClipobard";
import { VerticalSpacing } from "../../../components/VerticalSpacing";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { copyTextToClipboard } from "../../../utils/copyToClipboard";
import { getCertificateTypeLabel, getCertificateSeasonLabel } from "../../../utils/getCertificateLabel";
import { CertificatePanelWrapper, CertificateSeason, CertificatePaneBtnWrapper } from "./V3CertificatesPageLayout.styles";
import { useFormik } from "formik";
import { V3Dropdown } from "../../../components/Dropdown";
import { DropdownOptionCTA } from "../../../components/DropdownOptionCTA";
import { downloadPDF } from "../../../utils/downloadPDF";
import { CertificateSVG } from "./CertificateSVG";
import { openShareUrl } from "../../../utils/openShareUrl";
import { getDisplayName } from "../../../utils/getDisplayName";
import { CertificateType } from "authory-api-types/dist/enums";
import { V3Tooltip } from "../../../components/Tooltip";
import { LearnMoreCTA } from "../../../components/ReusableStyledComponents/LearnMoreCTA";
import { UnstyledInlineLink } from "../../../components/ReusableStyledComponents/UnstyledLink";
import { V3InputLabel } from "../../../components/SharedInputComponent";

const download = function (href: string, name: string) {
    var link = document.createElement('a');
    link.href = href;
    link.target = "_blank";
    link.download = name;
    link.click();
}

const setDownloader = (uid: string) => {
    // Get svg element
    var svg = document.getElementById(`svg_cert_element-${uid}`)

    // Get svg source
    var serializer = new XMLSerializer()
    var source = serializer.serializeToString(svg!);

    // Add name spaces
    if (!source.match(/^<svg[^>]*?\sxmlns=(['"`])https?\:\/\/www\.w3\.org\/2000\/svg\1/)) {
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"')
    }
    if (!source.match(/^<svg[^>]*?\sxmlns:xlink=(['"`])http\:\/\/www\.w3\.org\/1999\/xlink\1/)) {
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"')
    }

    // Add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source

    // Convert SVG source to URI data scheme
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source)

    // Set url value to a element's href attribute
    download(url, "certificate.svg");
}

const getCertificateTooltip = (certificateType: CertificateType) => {
    switch (certificateType) {
        case CertificateType.experienceLeader:
            return <>This certificate proves that your experience in writing articles is significantly higher than the work experience of an average writer. <LearnMoreCTA href="https://authory.com/help/en/articles/7733611-faq-experience-leader-certificate" /></>;
        case CertificateType.socialMediaSpecialist:
            return <>This certificate proves that your articles receive significantly higher engagement on social media compared to the average article. <LearnMoreCTA href="https://authory.com/help/en/articles/7733580-faq-social-media-specialist-certificate" /></>;
        case CertificateType.humanWriter:
            return <>This certificate proves that your articles are with a high degree of certainty written by a human, not by artificial intelligence. <LearnMoreCTA href="https://authory.com/help/en/articles/7733624-faq-human-writer-certificate" /></>;
        case CertificateType.longFormProfessional:
            return <>This certificate proves that you are significantly more experienced writing long - form articles than the average writer. <LearnMoreCTA href="https://authory.com/help/en/articles/7733620-faq-long-form-professional" /></>;
        case CertificateType.prolificExpert:
            return <>This certificate proves that you are significantly more experienced in creating a continuous output of articles than the average writer. <LearnMoreCTA href="https://authory.com/help/en/articles/7733615-faq-prolific-expert-certificate" /></>
        default:
            return <></>;
    }
}

const downloadImg = (targetWidth: number, certificate: CertificateResponse) => {
    const svgElement: HTMLOrSVGElement | null = document.getElementById(`svg_cert_element-${certificate.uid}`);
    if (!svgElement) return;

    //@ts-ignore
    let { width, height } = svgElement.getBBox();

    //@ts-ignore
    let clonedSvgElement = svgElement.cloneNode(true);

    const finalWidth = targetWidth;
    const finalHeight = finalWidth * height / width;

    let outerHTML = clonedSvgElement.outerHTML, blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });

    let URL = window.URL || window.webkitURL || window;
    let blobURL = URL.createObjectURL(blob);

    let image = new Image();

    image.onload = () => {

        let canvas = document.createElement('canvas');

        canvas.width = finalWidth;

        canvas.height = finalHeight;

        let context = canvas.getContext('2d');

        if (!context) return;
        context.drawImage(image, 0, 0, finalWidth, finalHeight);

        let png = canvas.toDataURL();

        download(png, `${getCertificateSeasonLabel(certificate.season)}_${getCertificateTypeLabel(certificate.type)}_${targetWidth}.png`);
    };

    image.src = blobURL;

    toast.success("Image download will start soon!");
}


export type CertificateFormik = {
    uuid: string,
    visible: boolean
}

type CertificatePanelProps = {
    user: AuthenticatedUserResponse,
    customDomain?: string,
    certificate: CertificateResponse,
    onCertificateFormSubmit: (data: CertificateFormik) => Promise<void>
}

export const CertificatePanel = ({ customDomain, certificate, user, onCertificateFormSubmit }: CertificatePanelProps) => {

    const size = useWindowSize();
    const url = customDomain ? `https://${customDomain}/certificate/${certificate.uid}` : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${user.slug}/certificate/${certificate.uid}`;
    let useShortButton = false

    if (size.width && size.width < 460) {
        useShortButton = true;
    } else {
        useShortButton = false;
    }

    const certificateFormik = useFormik<CertificateFormik>({
        enableReinitialize: true,
        initialValues: {
            uuid: certificate.uid,
            visible: certificate.visible
        },
        onSubmit: onCertificateFormSubmit,
    });

    const shareTitle = `${getDisplayName(user)}'s ${getCertificateSeasonLabel(certificate.season)} ${getCertificateTypeLabel(certificate.type)} certificate`;

    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(shareTitle);

    return <CertificatePanelWrapper>
        <div>
            <UnstyledInlineLink href={url} target="_blank">
                <CertificateSVG user={user} certificate={certificate} />
            </UnstyledInlineLink>
        </div>
        <div>
            <VerticalSpacing bottom={2}>
                <CertificatePaneBtnWrapper>
                    <div>
                        <V3HeadlineH3>{getCertificateTypeLabel(certificate.type)}</V3HeadlineH3>
                    </div>
                    <div>
                        <V3Tooltip
                            referenceContent={<V3IconHelpCircle />}
                            tooltipContent={getCertificateTooltip(certificate.type)}
                            tooltipMaxWidth={certificate.type === CertificateType.longFormProfessional ? 295 : 310}
                        />
                    </div>
                </CertificatePaneBtnWrapper>
            </VerticalSpacing>
            <VerticalSpacing bottom={30}>
                <CertificateSeason>{getCertificateSeasonLabel(certificate.season)}</CertificateSeason>
            </VerticalSpacing>
            <V3ToggleWithLabel
                name={`visible-${certificate.uid}`}
                checked={certificateFormik.values.visible}
                onChange={() => {
                    certificateFormik.setFieldValue("visible", !certificateFormik.values.visible)
                    certificateFormik.submitForm();
                }}
                label="Display on portfolio"
                useCaption
            />
        </div>
        <div>
            <div>
                <V3InputLabel><strong>Certificate link</strong></V3InputLabel>
            </div>
            <VerticalSpacing bottom={16} top={6}>
                <V3CCDisabledInput
                    as="input"
                    value={url}
                    readOnly
                    onFocus={(event: any) => {
                        event.target.select();
                        copyTextToClipboard(url || "", "URL copied!");
                    }}
                />
            </VerticalSpacing>
            <CertificatePaneBtnWrapper>
                <div>
                    <V3Dropdown
                        title={"Download"}
                        renderReferenceElement={() => {
                            return <V3Button
                                text=""
                                color={V3ButtonColor.secondary}
                                icon={<V3IconDownload />}
                                minWidth={60}
                            />
                        }}
                        maxWidth={221}
                        renderPopperElement={(open, setShowPopper) => {
                            if (!open) return <></>;

                            return <>
                                <DropdownOptionCTA
                                    label={"Badge (PNG, small)"}
                                    target="_blank"
                                    onClick={() => {
                                        setShowPopper(false);
                                        downloadImg(400, certificate);
                                    }}
                                    icon={<V3IconImage />}
                                />
                                <DropdownOptionCTA
                                    label={"Badge (PNG, medium)"}
                                    target="_blank"
                                    onClick={() => {
                                        setShowPopper(false);
                                        downloadImg(1000, certificate);
                                    }}
                                    icon={<V3IconImage />}
                                />
                                <DropdownOptionCTA
                                    label={"Badge (PNG, large)"}
                                    target="_blank"
                                    onClick={() => {
                                        downloadImg(3000, certificate);
                                        setShowPopper(false);
                                    }}
                                    icon={<V3IconImage />}
                                />
                                <DropdownOptionCTA
                                    label={"Badge (SVG)"}
                                    target="_blank"
                                    onClick={() => {
                                        setShowPopper(false);
                                        setDownloader(certificate.uid);
                                    }}
                                    icon={<V3IconImage />}
                                />
                                <DropdownOptionCTA
                                    label={"Certificate (PDF)"}
                                    target="_blank"
                                    onClick={() => {
                                        setShowPopper(false);
                                        toast.success("PDF will download soon");
                                        downloadPDF(`${getCertificateTypeLabel(certificate.type)}-${getCertificateSeasonLabel(certificate.season)}`, url);
                                    }}
                                    icon={<V3IconPDF />}
                                />
                            </>
                        }}
                    />
                </div>
                <div>
                    <V3Button
                        text={useShortButton ? "Copy" : "Copy Link"}
                        color={V3ButtonColor.secondary}
                        onClick={() => {
                            copyTextToClipboard(url || "", "URL copied!");
                        }}
                        icon={<V3IconCollections />}
                        minWidth={useShortButton ? 100 : 140}
                    />
                </div>
                <div>
                    <V3Dropdown
                        title={"Download"}
                        renderReferenceElement={() => {
                            return <V3Button
                                minWidth={useShortButton ? 100 : 140}
                                icon={<V3IconSend />}
                                text="Share"
                            />
                        }}
                        maxWidth={221}
                        renderPopperElement={(open, setShowPopper) => {
                            if (!open) return <></>;

                            return <>
                                <DropdownOptionCTA
                                    label={"Facebook"}
                                    onClick={() => {
                                        setShowPopper(false);
                                        openShareUrl(
                                            `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
                                            "Facebook"
                                        )
                                    }}
                                    icon={<V3IconFacebook />}
                                />
                                <DropdownOptionCTA
                                    label={"Twitter"}
                                    target="_blank"
                                    onClick={() => {
                                        setShowPopper(false);
                                        openShareUrl(
                                            `https://twitter.com/intent/tweet?text=${encodedTitle} ${encodedUrl}`,
                                            "Twitter"
                                        )
                                    }}
                                    icon={<V3IconTwitter />}
                                />
                                <DropdownOptionCTA
                                    label={"LinkedIn"}
                                    target="_blank"
                                    onClick={() => {
                                        setShowPopper(false);
                                        openShareUrl(
                                            `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedTitle}`,
                                            "Linkedin"
                                        )
                                    }}
                                    icon={<V3IconLinkedin />}
                                />
                            </>
                        }}
                    />
                </div>
            </CertificatePaneBtnWrapper>
        </div>
    </CertificatePanelWrapper>
}