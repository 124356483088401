import { UnstyledLink } from "../ReusableStyledComponents/UnstyledLink"
import { V3EmptyState } from "./V3EmptyStateScreen"

interface V3NoCertificatesProps { }

export const V3NoCertificates = ({ }: V3NoCertificatesProps) => {
    return <V3EmptyState
        customPadding="90px 20px"
        title={"Looks like you don't have any certificates yet."}
        description={<>Authory Certificates are awarded on a regular basis, and any certificates you receive will automatically show up here. <UnstyledLink textDecoration="underline" href="https://authory.com/help/en/articles/7733697-what-are-authory-certificates" target="_blank">Learn more about Certificates</UnstyledLink></>}
        svgIcon={<svg width="54" height="54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 13.333V6.667A6.667 6.667 0 0 1 20.667 0h26.666A6.667 6.667 0 0 1 54 6.667v26.666A6.667 6.667 0 0 1 47.333 40h-6.666" fill="#DDDFE3" />
            <rect y="5.5" width="48" height="48" rx="8" fill="#F4F4F6" />
            <path d="M37.333 9.5H10.667A6.667 6.667 0 0 0 4 16.167v26.666a6.667 6.667 0 0 0 6.667 6.667h26.666A6.667 6.667 0 0 0 44 42.833V16.167A6.667 6.667 0 0 0 37.333 9.5Z" stroke="#ABB0BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="8 8" />
            <path d="M24 32a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" stroke="#ABB0BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="m24.001 32.002 2.267 3.926 1.065-2.155 2.399.155-2.267-3.926M20.535 30.002l-2.267 3.926 2.399-.155 1.065 2.155L24 32.002" stroke="#ABB0BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>}
        maxWidth={600}>
    </V3EmptyState >
}