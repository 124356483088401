import { getCertificateColor, getCertificateSeasonLabel, getCertificateTypeLabel } from "../../../utils/getCertificateLabel";
import { CertSVG } from "./V3CertificatesPageLayout.styles";
import { CSSProperties } from "react";
import { AuthenticatedUserResponse, CertificateResponse, UserResponse } from "authory-api-types/dist/types";

const getNameFontSize = (len: number) => {
    if (len < 10) return 32;
    return 32 - (len - 10) * 2;
}

type CertificateSVGProps = {
    user: AuthenticatedUserResponse | UserResponse,
    certificate: CertificateResponse,
    maxWidth?: number,
}

export const CertificateSVG = ({ user, certificate, maxWidth = 152 }: CertificateSVGProps) => {

    const TextSyles: CSSProperties = {
        whiteSpace: "pre",
        textAnchor: "middle"
    };

    const firstName = user.firstName || "";
    const lastName = user.lastName || "";

    const certificateTopLabel = getCertificateTypeLabel(certificate.type).substring(0, getCertificateTypeLabel(certificate.type).lastIndexOf(" ")).trim();
    const certificateBottomLabel = getCertificateTypeLabel(certificate.type).substring(getCertificateTypeLabel(certificate.type).lastIndexOf(" ")).trim();

    const seasonLabel = getCertificateSeasonLabel(certificate.season).toUpperCase().trim();

    const firstNameFontSize = getNameFontSize(firstName.length);
    const firstLastFontSize = getNameFontSize(lastName.length);

    return <CertSVG $maxWidth={maxWidth} id={`svg_cert_element-${certificate.uid}`} viewBox="0 0 272 328" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#a)">
            <path d="M16 43.01C16 20.912 33.909 3 56 3h160c22.091 0 40 17.913 40 40.01v173.788a64.017 64.017 0 0 1-28.499 53.263l-69.313 46.219a39.99 39.99 0 0 1-44.376 0l-69.313-46.219A64.018 64.018 0 0 1 16 216.798V43.009Z" fill="#fff" />
            <path d="M56 4h160c21.539 0 39 17.465 39 39.01v173.788a63.017 63.017 0 0 1-28.054 52.431l-69.313 46.219a38.99 38.99 0 0 1-43.266 0l-69.313-46.219A63.018 63.018 0 0 1 17 216.798V43.009C17 21.465 34.461 4 56 4Z" stroke="#DDDFE3" strokeWidth="2" />
            <path d="M56 4h160c21.539 0 39 17.465 39 39.01v173.788a63.017 63.017 0 0 1-28.054 52.431l-69.313 46.219a38.99 38.99 0 0 1-43.266 0l-69.313-46.219A63.018 63.018 0 0 1 17 216.798V43.009C17 21.465 34.461 4 56 4Z" stroke="#757575" strokeOpacity=".2" strokeWidth="2" />
        </g>
        <circle cx="136" cy="283" r="24" fill="#2E77FF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M122 293h2.635l5.109-8.757a9.747 9.747 0 0 0 6.256 2.264 9.75 9.75 0 0 0 6.257-2.264l5.108 8.757H150l-14-24-14 24Zm15.712-16.094c0 .934-.768 1.695-1.712 1.695a1.706 1.706 0 0 1-1.712-1.695c0-.934.768-1.694 1.712-1.694s1.712.76 1.712 1.694Zm1.573 2.243 1.806 3.096a7.45 7.45 0 0 1-5.091 2.003 7.453 7.453 0 0 1-5.092-2.003l1.806-3.096A4.005 4.005 0 0 0 136 280.86c1.36 0 2.564-.679 3.285-1.711Z" fill="#fff" />
        <text fill="#6E7687" xmlSpace="preserve" style={TextSyles} fontFamily="Inter" fontSize="16" fontWeight="bold" letterSpacing=".02em">
            <tspan x="50%" y="239.818">{seasonLabel}</tspan>
        </text>
        <g filter="url(#b)">
            <path d="M4 143c0-8.837 7.163-16 16-16h232c8.837 0 16 7.163 16 16v48c0 8.837-7.163 16-16 16H20c-8.837 0-16-7.163-16-16v-48Z" fill={getCertificateColor(certificate.type)} />
        </g>
        <g filter="url(#c)">
            <text fill="#fff" xmlSpace="preserve" style={TextSyles} fontFamily="Inter" fontSize="24" fontWeight="600" letterSpacing=".02em">
                <tspan x="50%" y="162.727">{certificateTopLabel}</tspan>
                <tspan x="50%" y="188.727">{certificateBottomLabel}</tspan>
            </text>
        </g>
        <text fill="#17191C" xmlSpace="preserve" style={TextSyles} fontFamily="Lora" fontWeight="600" letterSpacing="0em">
            <tspan x="50%" y="64.212" fontSize={firstNameFontSize}>{firstName}</tspan>
            <tspan x="50%" y="99.212" fontSize={firstLastFontSize}>{lastName}</tspan>
        </text>
        <defs>
            <filter id="a" x="12" y="0" width="248" height="328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset dy="1" /><feGaussianBlur stdDeviation="2" /><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" /><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4326_37304" /><feBlend in="SourceGraphic" in2="effect1_dropShadow_4326_37304" result="shape" /></filter>
            <filter id="b" x="0" y="127" width="272" height="88" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset dy="4" /><feGaussianBlur stdDeviation="2" /><feComposite in2="hardAlpha" operator="out" /><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" /><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4326_37304" /><feBlend in="SourceGraphic" in2="effect1_dropShadow_4326_37304" result="shape" /></filter>
            <filter id="c" x="60.344" y="144.702" width="151.883" height="46.563" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset dx="2" dy="2" /><feComposite in2="hardAlpha" operator="out" /><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" /><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4326_37304" /><feBlend in="SourceGraphic" in2="effect1_dropShadow_4326_37304" result="shape" /></filter>
        </defs>
    </CertSVG>
}