import { generatePDF } from "../api/pdf";

export const downloadPDF = async (title?: string | null, url?: string) => {
    let res = "";
    const fileName = encodeURIComponent(`${title || "content"}`.replace(/[^a-z0-9]/gi, '_').toLowerCase());

    if (!url?.length) {
        const allCSS = [...document.styleSheets]
            .map(styleSheet => {
                try {
                    return [...styleSheet.cssRules]
                        .map(rule => rule.cssText)
                        .join('');
                } catch (e) {
                    console.log('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);
                }
            })
            .filter(Boolean)
            .join('\n');

        // Step 1: Create a new document
        var newDocument = document.implementation.createHTMLDocument();

        // Step 2: Set the HTML content of the new document to the current page's content
        newDocument.documentElement.innerHTML = document.documentElement.innerHTML;

        // Step 3: Remove the script tag from the new document
        var scriptTags = newDocument.querySelectorAll('script');

        for (let index = 0; index < scriptTags.length; index++) {
            const el = scriptTags[index];
            if (el.id === "vwoCode" || el.src.startsWith("https://dev.visualwebsiteoptimizer.com")) {
                el.remove();
            }
        }

        const finalHTML = `${newDocument.documentElement.innerHTML}<style>${allCSS}</style>`;

        const removedFade = finalHTML.replace(/opacity: 0/g, 'opacity: 1');

        res = await generatePDF({ html: removedFade, fileName });
    } else {
        res = await generatePDF({ url, fileName });
    }

    var link = document.createElement('a');
    link.href = res.startsWith("https") ? res : `https://${res}`;
    link.target = "_blank";
    link.download = `${fileName}.pdf`;
    link.click();
}