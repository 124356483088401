
import { AuthenticatedUserResponse, CertificateResponse } from "authory-api-types/dist/types";
import { V3ContentLargeWrapper } from "../../../components/ReusableStyledComponents/V3ContentLargeWrapper";
import { V3LightPanel } from "../../../components/V3LightPanel";
import { V3SettingsLinksPanel } from "../../../components/V3LinksPanel";
import { VerticalSpacing } from "../../../components/VerticalSpacing";
import { SidebarLayout } from "../../../layout/SideBarLayout";
import { CertificateFormik, CertificatePanel } from "./CertificatePanel";
import { V3SimpleNavbar } from "../../../components/V3SimpleNavbar";
import { LearnMoreCTA } from "../../../components/ReusableStyledComponents/LearnMoreCTA";
import { V3NoCertificates } from "../../../components/V3EmptyStateScreen/V3NoCertificates";
import { V3Loader } from "../../../components/Loader";
import { CertificateType } from "authory-api-types/dist/enums";

interface V3CertificatesPageLayoutProps {
    user: AuthenticatedUserResponse,
    customDomain?: string,
    certificates: CertificateResponse[]
    onCertificateFormSubmit: (data: CertificateFormik) => Promise<void>,
    certificatesLoading: boolean,
}

export const V3CertificatesPageLayout = ({ user, customDomain, certificates, onCertificateFormSubmit, certificatesLoading = false }: V3CertificatesPageLayoutProps) => <SidebarLayout
    title="Certificates"
    disableFadeIn
    leftPanel={
        <V3SettingsLinksPanel />
    }
    topNavBarContent={<V3SimpleNavbar
        title="Settings"
        tooltipContent={<>If you're awarded a certificate, it's a testament to your outstanding performance in one of 5 distinct skillsets. <LearnMoreCTA href="https://authory.com/help/en/articles/7733697-what-are-authory-certificates" /></>}
        placement="right"
        customTooltipWith={280}
    />}
    rightPanel={<V3ContentLargeWrapper>
        <VerticalSpacing bottom={48}>
            {
                !certificatesLoading ? <>
                    <V3SimpleNavbar
                        title="Certificates"
                        withTitle
                    />
                    {
                        !!certificates.length ? certificates
                            .sort((a, b) => {
                                if (a.season === b.season) {
                                    return a.type === CertificateType.humanWriter ? -1 : 0;
                                }

                                return 0;
                            })
                            .map(certificate => <VerticalSpacing bottom={32} key={certificate.uid}>
                                <V3LightPanel
                                    panels={[<CertificatePanel
                                        user={user}
                                        customDomain={customDomain}
                                        certificate={certificate}
                                        onCertificateFormSubmit={onCertificateFormSubmit}
                                    />]}
                                />
                            </VerticalSpacing>) : <V3NoCertificates />
                    }
                </>
                    : <VerticalSpacing top={32} bottom={32}>
                        <V3Loader justify="center" />
                    </VerticalSpacing>
            }
        </VerticalSpacing>
    </V3ContentLargeWrapper>}
/>